
export function navTabs() {
    let tabs = document.querySelectorAll('.section-tabs');

    if(!tabs){ return }

    tabs.forEach(function(tabEl, index){
        let tabsItems = tabEl.querySelectorAll('.tabs__nav__item a');
        let tabPanes = tabEl.querySelectorAll('.tabs__content__pane');
        let hasActive = false;
    
        tabsItems.forEach(function(el, index){
            if(el.classList.contains('active')){
                hasActive = true;
            }
    
            el.addEventListener('click', function(e){
    
                e.preventDefault();
    
                tabsItems.forEach(function(item) {
                    item.parentNode.classList.remove('active')
                })
                tabPanes.forEach(function(item) {
                    item.classList.remove('active')
                })
    
                el.parentNode.classList.add('active')
                tabEl.querySelector(el.getAttribute('href')).classList.add('active')
            });
        });
    
        if(!hasActive && tabsItems[0]){
            tabsItems[0].click();
        }
    })
}


export function accordion() {
    let accordionTitles = document.querySelectorAll('.accordion-item dt');

    accordionTitles.forEach(function(el, index){
        el.addEventListener('click', function(){

            if(el.parentNode.classList.contains('active')){
                el.parentNode.classList.remove('active');
            }else{
                // hide active el
                let activeTitle = getActiveEl();

                if(activeTitle){
                    activeTitle.forEach((elem) => {
                    elem.parentNode.classList.remove('active');
                    });
                }

                el.parentNode.classList.add('active');
            }
        });
    });

    function getActiveEl(){
        return document.querySelectorAll('.accordion-item.active dt');
    }

    function getActiveContentEl(){
        return document.querySelectorAll('.accordion-item.active dd');
    }
}