import { Modal } from "bootstrap";

export default function modal() {
	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}
}
