export function viewAnimation() {
    // Components loading animations
    let elementsToObserve = document.querySelectorAll('.view-animation');

    // intersection observer not support on ie11
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    elementsToObserve.forEach(function(el, index){
        if(isIE11){
            el.classList.add('animated');
        }else{
            new IntersectionObserver(
                function (entries) {
                    if (entries[0].isIntersecting === true) {
                        entries[0].target.classList.add('animated')
                    }
                },
            { threshold: [.5] }
            ).observe(el);
        }
    });
}


export function smoothScroll() {
  let blacklist = [
    '#popup-',
  ]
  $(document).on('click', 'a[href*="#"]:not([href="#"]):not([data-bs-toggle])', function (event) {
      var target = $(this).attr('href');

      if(blacklist.some( (check) => {
        return target.includes(check)
      })) {
        return false;
      }

      if ($(target).length) {
          event.preventDefault();

          $('html, body').animate({
              scrollTop: $(target).offset().top - ( $('.site-header').outerHeight() + 200 )
          }, 600);
      }
  });
}
