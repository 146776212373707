import {accordion, navTabs} from './components/faqs';
import {swiperDesktop, swiperMobile} from './components/swiper';
import {hamburger, mobileHeader, stickyHeader} from './components/header';
import { viewAnimation, smoothScroll } from "./util/animation";
import {outputMonth, countDown} from './util/dates';
import {inlineVideo} from './util/video';
import {phoneConcat, readMore, rolodex} from './util/text';
import {cf7Redirect} from './util/window';
import collapsible from './components/collapsible';
import modal from './components/modal';


(function($) {


    $(window).on('load', function () {

        collapsible();
        modal();
        mobileHeader();
        stickyHeader();
        hamburger();
        outputMonth();
        viewAnimation();
        accordion();
        navTabs();
        swiperDesktop();
        swiperMobile();
        // smoothScroll();
        // inlineVideo();
        // phoneConcat();
        rolodex();

    });


})( jQuery );
